<!--
 * @Author: dongjia
 * @Date: 2022-03-30 15:11:36
 * @LastEditTime: 2022-03-31 10:55:22
 * @LastEditors: aleaner
 * @Description: 拉黑成员弹窗
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\community-admin\components\SetMemberBlackDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="拉黑" :visible.sync="openDialog" width="500px" :show-close="true" class="dialog-vertical"
    @close="cancel('noCheck')">
    <el-form size="medium" :model="noCheckData" :rules="rules" ref="noCheck" label-width="70px">
      <el-form-item label="原因：" prop="black_reason">
        <el-input type="textarea" :rows="6" maxlength="20" show-word-limit v-model="noCheckData.black_reason"
          placeholder="请输入原因"></el-input>
        <div class="form-tip" style="margin-top: 5px">
          <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC;line-height: 19px;"></span>
          <span>拉黑用户后，用户无法在该小组中发贴、评论，并且会将用户移出该小组，无法加入。将通过系统消息、短信通知</span>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('noCheck')" :loading="saveLoading">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { setBlack } from "../api/black-member.js";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    blackType: {
      type: String,
      default: "",
    },
    id: {
      type: [String, Number],
      default: "",
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      saveLoading: false,
      optionsLoading: false,
      noCheckData: {
        black_reason: "",
      },
      rules: {
        black_reason: [
          { required: true, message: "请输入原因", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          let noCheckData = { ...this.noCheckData, id: this.id, is_black: 1 };
          setBlack(noCheckData, this.blackType)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.$refs[formName].resetFields();
              this.openDialog = false;
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-tip {
  display: flex;
  font-size: 12px;
  color: #606266;
  line-height: 18px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
</style>