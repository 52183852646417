<!--
 * @Author: dongjia
 * @Date: 2021-08-16 15:10:50
 * @LastEditTime: 2022-04-22 19:02:33
 * @LastEditors: aleaner
 * @Description: 帖子列表页
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\community-admin\views\post-admin\list.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page">
    <reminder
      title="温馨提示"
      text="置顶说明：按置顶时间显示前两条数据"
    ></reminder>
    <el-button
      class="top-btn"
      type="primary"
      size="small"
      @click="handleAddPost"
      >新增
    </el-button>
    <el-button
      class="top-btn"
      size="small"
      v-if="$route.params.topic_id"
      @click="goBack"
    >
      返回
    </el-button>
    <list-filter
      :filterForm="filterForm"
      :page="pageData.current_page"
      :uploadFilter="ok"
    />
    <list-tabs
      v-model="filterForm.tab"
      :tabs="tabArr"
      :isLoading="loading"
      @tab-click="handleClick"
    >
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn">
        <el-button
          type="primary"
          v-if="filterForm.tab === 'checked'"
          size="small"
          @click="handleRecommend"
        >
          设为精华帖
        </el-button>
        <el-button
          v-if="['checking', 'uncheck'].includes(filterForm.tab)"
          size="small"
          @click="handleChecked"
        >
          通过
        </el-button>
        <el-button
          v-if="filterForm.tab === 'checking'"
          size="small"
          @click="handleNoChecked"
        >
          不通过
        </el-button>
        <el-button type="danger" size="small" @click="handleDel"
          >删除
        </el-button>
        <el-button
          v-if="filterForm.tab === 'deleted'"
          type="success"
          size="small"
          @click="handleReduct"
        >
          还原
        </el-button>
      </div>
      <el-table
        :data="tbody"
        class="thead-light"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column
          label="操作"
          :width="handleLabelWidth[filterForm.tab]"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-if="['checking', 'uncheck'].includes(filterForm.tab)"
              size="small"
              @click="checkedRow(scope.row)"
              type="text"
            >
              通过
            </el-button>
            <el-button
              v-if="filterForm.tab === 'checking'"
              size="small"
              @click="noCheckedRow(scope.row)"
              type="text"
            >
              不通过
            </el-button>
            <el-button
              v-if="['checked', 'uncheck'].includes(filterForm.tab)"
              size="small"
              @click="detailRow(scope.row)"
              type="text"
            >
              详情
            </el-button>
            <el-button
              v-if="filterForm.tab === 'checked' && !scope.row.is_top"
              size="small"
              @click="setTopRow(scope.row)"
              type="text"
            >
              置顶
            </el-button>
            <el-button
              v-if="filterForm.tab === 'checked' && scope.row.is_top"
              size="small"
              @click="cancelTopRow(scope.row)"
              type="text"
            >
              取消置顶
            </el-button>
            <el-button
              v-if="filterForm.tab === 'deleted'"
              size="small"
              @click="ReductRow(scope.row)"
              type="text"
            >
              还原
            </el-button>
            <el-button
     
              size="small"
              @click="deleteRow(scope.row)"
              type="text"
              >删除</el-button
            >
            <template v-if="filterForm.tab !== 'deleted'">
              <el-divider direction="vertical" />
              <el-dropdown @command="handleClickRow($event, scope.row)">
                <span class="el-dropdown-link" style="line-height: 34px"
                  >更多</span
                >
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="filterForm.tab === 'checking'"
                    command="detailRow"
                    >详情
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="
                      scope.row.black_status && filterForm.tab !== 'deleted'
                    "
                    command="cancelblackRow"
                    >移出
                  </el-dropdown-item>
                  <!--  -->
                  <el-dropdown-item
                    v-if="
                      !scope.row.black_status && filterForm.tab !== 'deleted'
                    "
                    :disabled="scope.row.is_admin == 1"
                    command="blackRow"
                    >拉黑
                  </el-dropdown-item>
                  <!-- <el-dropdown-item command="deleteRow">删除 </el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead[theadKey]">
          <el-table-column
            :show-overflow-tooltip="th.prop !== 'content'"
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            :sortable="th.sortable"
          >
            <template slot-scope="scope">
              <el-checkbox
                v-if="th.prop === 'is_recommend' || th.prop === 'is_suggest'"
                v-model="tbody[scope.$index][th.prop]"
                :true-label="1"
                :false-label="0"
                @change="changeStatus(scope.row, th.prop)"
              >
              </el-checkbox>
              <div class="black-member" v-else-if="th.prop === 'author_name'">
                <el-tooltip
                  placement="top"
                  :content="scope.row[th.prop]"
                  effect="dark"
                  :disabled="!scope.row.black_status"
                >
                  <p
                    :style="{
                      width: scope.row.black_status ? '70px' : 'auto',
                    }"
                  >
                    {{ scope.row[th.prop] | placeholder }}
                  </p>
                </el-tooltip>
                <div class="black-tag" v-if="scope.row.black_status">
                  黑名单
                </div>
              </div>
              <el-popover
                v-else-if="th.prop === 'content'"
                placement="bottom"
                width="374"
                trigger="hover"
              >
                <div style="white-space: pre-line">{{ scope.row.content }}</div>
                <!-- popover图片 -->
                <div
                  v-if="
                    scope.row.images &&
                    scope.row.images.length &&
                    scope.row.type === 0
                  "
                  :style="{
                    marginTop: scope.row.content ? '13px' : '',
                  }"
                  class="popover-imgs"
                >
                  <el-image
                    v-for="(img, index) in scope.row.images"
                    class="popover-img cursor-pointer"
                    :style="{
                      marginTop: index >= 3 ? '16px' : '',
                    }"
                    :src="fixImageUrl(img.url)"
                    @click="$previewImage([img.url])"
                    fit="cover"
                    :key="index"
                  >
                  </el-image>
                </div>
                <!-- popover视频 -->
                <div
                  class="popover-video"
                  v-if="
                    scope.row.video &&
                    scope.row.video[0] &&
                    scope.row.type === 1
                  "
                  :style="{
                    marginTop: scope.row.content ? '13px' : '',
                  }"
                >
                  <el-image
                    class="video-thumbnail"
                    :src="fixImageUrl(scope.row.video[0].thumbnail)"
                    fit="cover"
                  >
                  </el-image>
                  <div
                    class="video-mask cursor-pointer"
                    @click="handlePlay(scope.row.video[0])"
                  >
                    <img
                      class="video-play"
                      src="@/base/assets/images/media/video-play@2x.png"
                    />
                  </div>
                </div>
                <!-- 帖子内容 -->
                <div
                  class="list-contents"
                  slot="reference"
                  v-if="scope.row.content"
                >
                  {{ scope.row.content }}
                </div>
                <div class="list-contents" slot="reference" v-else>
                  <el-image
                    v-if="scope.row.images.length && scope.row.type === 0"
                    class="list-icon cursor-pointer"
                    :src="fixImageUrl(scope.row.images[0].url)"
                    @click="$previewImage([scope.row.images[0].url])"
                    fit="cover"
                  >
                  </el-image>
                  <div
                    class="content-video"
                    v-if="scope.row.video[0] && scope.row.type === 1"
                  >
                    <el-image
                      class="video-thumbnail"
                      :src="fixImageUrl(scope.row.video[0].thumbnail)"
                      fit="cover"
                    ></el-image>
                    <div
                      class="video-mask cursor-pointer"
                      @click="handlePlay(scope.row.video[0])"
                    >
                      <img
                        class="video-play"
                        src="@/base/assets/images/media/video-play@2x.png"
                      />
                    </div>
                  </div>
                </div>
              </el-popover>
              <div v-else-if="th.prop === 'count'">
                {{
                  `${scope.row.view_count}/${scope.row.like_count}/${scope.row.comments_count}/${scope.row.collects_count}/${scope.row.share_count}`
                }}
              </div>
              <!-- 举报数 -->
              <div v-else-if="th.prop === 'complaint_count'">
                {{ scope.row[th.prop] }}
                <el-button
                  v-if="scope.row[th.prop] > 0"
                  type="text"
                  @click="checkReportRecode(scope.row)"
                  >查看
                </el-button>
              </div>
              <!-- <el-image v-else-if="th.prop === 'icon'" class="list-icon cursor-pointer"
                :src="fixImageUrl(scope.row[th.prop])" @click="$previewImage([scope.row[th.prop]])">
              </el-image> -->
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
    <!-- <AddPost v-model="openAddPost" :Data="PostConfig" :handleType="changeRowType"
      :updateList="refreshList"></AddPost> -->
    <NoCheckedDialog
      v-model="openNoCheckedDialog"
      :Data="noCheckData"
      :updateList="refreshList"
    />
    <VideoDialog />
    <SetTopDialog
      v-model="showSetTopDialog"
      :post_id="setTopId"
      :updateList="refreshList"
    />
    <!-- 拉黑弹窗 -->
    <SetMemberBlackDialog
      v-model="showSetBlackDialog"
      :id="setTopId"
      blackType="post"
      :updateList="refreshList"
    />
    <!-- 举报记录弹窗 -->
    <ReportRecodeDialog
      v-model="showReportRecodeDialog"
      :requestData="ReportRecodeRequest"
    />
  </div>
</template>

<script>
import listFilter from "@/modules/community-admin/components/postAdmin/ListFilter";
import Pagination from "@/base/components/Default/Pagination";
// import AddPost from "../../components/postAdmin/AddPost";
import NoCheckedDialog from "@/modules/community-admin/components/postAdmin/NoCheckedDialog";
import SetTopDialog from "@/modules/community-admin/components/postAdmin/SetTopDialog";
import VideoDialog from "@/base/components/Preview/VideoViewer";
import Reminder from "@/modules/common/components/Reminder";
import SetMemberBlackDialog from "@/modules/community-admin/components/SetMemberBlackDialog.vue";
import ReportRecodeDialog from "@/modules/common/components/ReportRecodeDialog";
import {
  postList,
  updateShow,
  updateRecommend,
  updateSuggest,
  softDelete,
  Delete,
  putBack,
  check,
  cancelTop,
  activityPostList,
} from "@/modules/community-admin/api/post-admin/list";
import { setBlack } from "@/modules/community-admin/api/black-member.js";
export default {
  data() {
    return {
      tabArr: [
        { label: "已审核", name: "checked" },
        { label: "待审核", name: "checking", badge: 1 },
        { label: "未通过", name: "uncheck" },
        { label: "回收站", name: "deleted" },
      ],
      loading: false, //加载
      thead: {
        checked: [
          { label: "帖子内容", prop: "content", minWidth: 160 },
          { label: "发帖人", prop: "author_name", minWidth: 130 },
          { label: "所属话题", prop: "topic_name", minWidth: 110 },
          { label: "所属小组", prop: "group_name", minWidth: 110 },
          {
            label: "浏览/点赞/评论/收藏/分享",
            prop: "count",
            minWidth: 220,
            sortable: true,
          },
          {
            label: "举报数",
            prop: "complaint_count",
            minWidth: 100,
            sortable: true,
          },
          {
            label: "发布时间",
            prop: "create_time",
            minWidth: 160,
            sortable: true,
          },
          {
            label: "置顶时间",
            prop: "top_time",
            minWidth: 320,
            sortable: true,
          },
          // {
          //   label: "推荐",
          //   prop: "is_suggest",
          //   minWidth: 80,
          // },
          {
            label: "精华帖",
            prop: "is_recommend",
            minWidth: 80,
          },
        ],
        unchecked: [
          { label: "帖子内容", prop: "content", minWidth: 160 },
          { label: "发帖人", prop: "author_name", minWidth: 130 },
          { label: "所属话题", prop: "topic_name", minWidth: 110 },
          {
            label: "发布时间",
            prop: "create_time",
            minWidth: 160,
            sortable: true,
          },
        ],
      },
      moreHandleList: [
        { name: "拉黑", command: "blackRow" },
        { name: "删除", command: "deleteRow" },
      ],
      //筛选对象
      filterForm: {
        group_id: -1,
        topic_id: -1,
        top_status: -1,
        content: "", // 帖子内容
        author_name: "", // 创建者名称
        recommend_status: -1, // 推荐状态
        create_start_time: -1, //开始时间
        create_end_time: -1, //结束时间
        top_start_time: -1, // 置顶开始时间
        top_end_time: -1, //置顶结束时间
        tab: "checking", //当前筛选tab
        page_size: 15, //每页多少条数据
      },
      // 筛选缓存
      filters: {
        checked: null,
        checking: null,
        uncheck: null,
        deleted: null,
      },
      handleLabelWidth: {
        checked: 250,
        checking: 250,
        uncheck: 150,
        deleted: 150,
      },
      addPostRouteName: {
        group: "addGroupPost",
        topic: "addTopicPost",
        group_topic: "addGroupTopicPost",
        none: "addPost",
      },
      pageData: {
        page_size: 15,
        tab: "checked",
      },
      tbody: [],
      selectArr: [], //勾选的数组id
      PostConfig: {},
      noCheckData: {},
      setTopId: "", // 当前置顶id
      showSetTopDialog: false, // 是否显示置顶弹窗
      // openAddPost: false, // 开启设置弹窗
      openNoCheckedDialog: false,
      showSetBlackDialog: false,
      changeRowType: "change",
      showReportRecodeDialog: false, //是否显示举报记录弹窗
      ReportRecodeRequest: {
        page: 1,
        page_size: 15,
        data_type: "circle", // 举报数据类型
        data_table: "circle_post", // 举报数据表
        data_id: "", // 举报内容id
        report_total: 0, // 举报数
      },
      // 适配活动详情-发帖列表，从这里进入禁用话题筛选
      tidDisabled: false,
      // 是否是活动详情进入
      isActivity: false,
    };
  },
  computed: {
    theadKey() {
      return ["checking", "uncheck", "deleted"].includes(this.filterForm.tab)
        ? "unchecked"
        : this.filterForm.tab;
    },
    addRouterName() {
      if (this.$route.params.id && this.$route.params.topic_id) {
        return this.addPostRouteName.group_topic;
      } else if (this.$route.params.id && !this.$route.params.topic_id) {
        return this.addPostRouteName.group;
      } else if (!this.$route.params.id && this.$route.params.topic_id) {
        return this.addPostRouteName.topic;
      } else {
        return this.addPostRouteName.none;
      }
    },
    detailRouterName() {
      if (this.$route.name === "ActivityPostList") {
        // 活动帖子
        return "ActivityPostDetail";
      }
      if (this.$route.params.id && this.$route.params.topic_id) {
        return "groupTopicPostDetail";
      } else if (this.$route.params.id && !this.$route.params.topic_id) {
        return "groupPostDetail";
      } else if (!this.$route.params.id && this.$route.params.topic_id) {
        return "topicPostDetail";
      } else {
        return "postDetail";
      }
    },
    backRouterName() {
      if (this.$route.params.id && this.$route.params.topic_id) {
        return "groupTopicList";
      } else if (this.$route.params.id && !this.$route.params.topic_id) {
        return "groupPostList";
      } else if (!this.$route.params.id && this.$route.params.topic_id) {
        return "CircleTopicList";
      }
    },
  },
  methods: {
    // 获取品牌列表
    getPostList(pageData) {
      // this.tbody = [];
      this.pageData = {};
      this.loading = true;

      postList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.selectedMembers = data.user_ids;
          // this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 新增话题
    handleAddPost() {
      this.$router.push({
        name: this.addRouterName,
        params: {
          id: 0,
          group_id: this.$route.params.id,
          topic_id: this.$route.params.topic_id,
        },
      });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (this.filterForm.tab !== "deleted") {
          this.$msgbox
            .confirm("确定要将选中数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将选中数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        }
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (this.filterForm.tab !== "deleted") {
        this.$msgbox
          .confirm("确定要将此数据移入回收站吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      } else {
        this.$msgbox
          .confirm("确定要将此数据永久删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      if (this.filterForm.tab !== "deleted") {
        softDelete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        Delete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm("确定要将此数据还原吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.Reduct({ id: row.id });
        })
        .catch((err) => {});
    },
    // 还原数据
    Reduct(id) {
      this.loading = true;
      putBack(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击通过
    checkedRow(row) {
      this.$msgbox
        .confirm("确定要通过该数据吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.loading = true;
          check({ id: row.id })
            .then((res) => {
              this.$message.success(res.msg);
              this.refreshList();
            })
            .catch((err) => {
              this.refreshList();
            });
        })
        .catch((err) => {});
    },
    // 批量通过
    handleChecked() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要通过这些数据吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.loading = true;
            check({ id: this.selectArr })
              .then((res) => {
                this.$message.success(res.msg);
                this.refreshList();
              })
              .catch((err) => {
                this.refreshList();
              });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击不通过
    noCheckedRow(row) {
      this.noCheckData = {
        id: row.id,
        reason: "",
      };
      this.openNoCheckedDialog = true;
    },
    // 批量不通过
    handleNoChecked() {
      if (this.selectArr.length) {
        this.noCheckData = {
          id: this.selectArr,
          reason: "",
        };
        this.openNoCheckedDialog = true;
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 批量设为推荐
    handleRecommend() {
      if (this.selectArr.length) {
        this.loading = true;
        updateRecommend({ id: this.selectArr, is_recommend: 1 })
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch((err) => {
            this.refreshList();
          });
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 拉黑数据
    blackRow(row) {
      this.showSetBlackDialog = true;
      this.setTopId = row.id;
    },
    // 取消拉黑
    cancelblackRow(row) {
      this.loading = true;
      setBlack({ id: row.id, is_black: 0 }, "post")
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.refreshList();
        });
    },
    // 查看举报记录
    checkReportRecode({ id }) {
      this.ReportRecodeRequest.data_id = id;
      this.showReportRecodeDialog = true;
    },
    // 点击详情
    detailRow(row) {
      this.$router.push({
        name: this.detailRouterName,
        params: {
          id: row.id, // 帖子id
          group_id: this.$route.params.id, // 活动id
          topic_id: this.$route.params.topic_id,
          ...(this.detailRouterName === "ActivityPostList"
            ? {
                type: this.$route.params.type,
                activity_id: this.$route.params.id,
              }
            : {}),
        },
      });
    },
    // 置顶帖子
    setTopRow(row) {
      this.showSetTopDialog = true;
      this.setTopId = row.id;
    },
    // 取消置顶帖子
    cancelTopRow(row) {
      this.$msgbox
        .confirm("确定要取消置顶吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.loading = true;
          cancelTop({ id: row.id })
            .then((res) => {
              this.$message.success(res.msg);
              this.refreshList();
            })
            .catch((err) => {
              this.refreshList();
            });
        })
        .catch((err) => {});
    },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          group_id: -1,
          topic_id: this.$route.params.topic_id || -1,
          content: "", // 话题名称
          author_name: "", // 创建者名称
          top_status: -1,
          recommend_status: -1, // 推荐状态
          create_start_time: -1, //开始时间
          create_end_time: -1, //结束时间
          top_start_time: -1, // 置顶开始时间
          top_end_time: -1, //置顶结束时间
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        };
      }
      this.getPostList(this.filterForm);
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getPostList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getPostList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    refreshList() {
      this.getPostList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 点击下拉菜单
    handleClickRow(value, row) {
      if (value === "detailRow") {
        this.$router.push({
          name: this.detailRouterName,
          params: { id: row.id },
        });
      } else {
        this[value](row);
      }
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      const data = {};
      data.id = e.id;
      data[key] = e[key];
      this.loading = true;
      if (key == "is_recommend") {
        // 更新精华状态
        updateRecommend(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      } else if (key === "is_suggest") {
        // 更新推荐状态
        updateSuggest(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      } else {
        // 更新排序状态
        updateSort(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      }
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getPostList(pageData);
    },
    // 返回上一页
    goBack() {
      try {
        this.$router.go(-1);
      } catch (err) {
        this.$router.push({
          name: this.backRouterName,
          params: {
            id: this.$route.params.id,
            topic_id: this.$route.params.topic_id,
          },
        });
      }
    },
    // 视频播放
    handlePlay(data) {
      this.$store.commit("media/setIsOpenMediaVideoDialog", true);
      this.$store.commit("media/setDialogDetail", data);
    },
  },
  created() {
    if (this.$route.params.id && !this.$route.params.topic_id) {
      this.filterForm.group_id = this.$route.params.id;
    }
    if (this.$route.params.topic_id) {
      this.filterForm.topic_id = this.$route.params.topic_id;
    }
    if (this.$route.query.tab) {
      this.filterForm.tab = this.$route.query.tab;
    }
    // this.getPidList(); //获取上级组织列表
    this.getPostList(this.filterForm); //获取列表数据
  },
  components: {
    listFilter,
    Pagination,
    NoCheckedDialog,
    VideoDialog,
    SetTopDialog,
    Reminder,
    SetMemberBlackDialog,
    ReportRecodeDialog,
  },
};
</script>

<style lang="scss" scoped>
.container {
  .body {
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }
  }
}
.black-member {
  display: flex;
  align-items: center;
  p {
    @include nowrap();
  }
  .black-tag {
    margin-left: 10px;
    width: 44px;
    height: 28px;
    background-color: rgb(85, 85, 85);
    font-size: 12px;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.list-icon {
  width: 60px;
  height: 60px;
}
.list-contents {
  @include clamp(1);
  white-space: pre-line;
}
.popover-imgs {
  display: flex;
  flex-wrap: wrap;
  .popover-img {
    width: 90px;
    height: 90px;
    margin-right: 16px;
    border-radius: 4px;
  }
}
.content-video {
  position: relative;
  width: 105px;
  height: 60px;
  .video-thumbnail {
    width: 100%;
    height: 100%;
  }
  .video-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);

    .video-play {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
}
.popover-video {
  position: relative;
  width: 347px;
  height: 191px;
  .video-thumbnail {
    width: 100%;
    height: 100%;
  }
  .video-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);

    .video-play {
      display: inline-block;
      width: 46px;
      height: 46px;
    }
  }
}
</style>
